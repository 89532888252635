import React, { useEffect, useState } from "react";
import ScoreCard from "../ScoreCard";
import axiosInstance from "../../Features/axios";
import socket from "../../Features/socket";
import { COMMENTARY_CONNECTION, COMMENTARY_DISCONNECTION, CONNECT, LIVE, RUNNER_CONNECTION, UPCOMMING } from "../../utilities/const";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { FreeMode } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../banner.css";
import { sortMatchesByDateTime } from "../Reusables/Methods";
import { getMatchType } from "../../utilities/functions";
import ScoreHeaderSkeleton from "../Skeleton/ScoreHeaderSkeleton";
import { useAppContext } from "../../Context/AppContext";

const ScoreHeader = () => {
  const [liveMatchData, setLiveMatchData] = useState([]);
  const [eventIds, setEventIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const { liveUpcomingData } = useAppContext();

  const removeCard = (eventId) => {
    setLiveMatchData(prev => prev.filter(match => match?.eid !== eventId))
    setEventIds(prev => prev.filter(eid => eid !== eventId))
  }

  useEffect(() => {
    if(liveUpcomingData?.scheduleMatches || liveUpcomingData?.liveMatches) {
    const data = sortMatchesByDateTime([...liveUpcomingData?.scheduleMatches, ...liveUpcomingData?.liveMatches], false);
    setLiveMatchData(data);
    const eventIdsData = data && data?.map((match) => match?.eid);
    setEventIds(eventIdsData);
    setLoading(false);
    }
  }, [liveUpcomingData]);
  //location.pathname
  
  useEffect(() => {
    if (eventIds.length && socket) {
      if (socket?.connected) {
        socket.emit(COMMENTARY_CONNECTION, eventIds);
      } else {
        socket?.on(CONNECT, () => {
          socket?.emit(COMMENTARY_CONNECTION, eventIds);
        });
      }
    }
    return () => {
      if (socket) {
        socket?.emit(COMMENTARY_DISCONNECTION, eventIds);
      }
    }
  }, [eventIds]);

  useEffect(() => {
    if (eventIds.length && socket) {
      if (socket?.connected) {
        socket?.emit(RUNNER_CONNECTION, eventIds);
      } else {
        socket?.on(CONNECT, () => {
          socket?.emit(RUNNER_CONNECTION, eventIds);
        });
      }
    }
  }, [eventIds]);

  const today = new Date().toISOString().split("T")[0];

  return (
    <section className="header-middle d-flex align-items-center">
      {/* <div className="ml-10">
        <img src={"/assets/images/batter.png"} alt="batter" style={{ height: "130px", borderRadius: "100%"}}/>
      </div> */}
      <div className="container">
        <div className="header-cards">
          <Swiper
            slidesPerView={4}
            spaceBetween={10}
            freeMode={true}
            className="mySwiper"
            centeredSlidesBounds={false}
            centeredSlides={false}
            navigation={{
              prevEl: ".swiper-button-prev",
              nextEl: ".swiper-button-next",
            }}
            modules={[FreeMode, Navigation]}
            breakpoints={{
              // when window width is >= 320px
              200: {
                slidesPerView: 1,
                spaceBetween: 10, // Adjust this value for smaller screens if needed
              },
              // when window width is >= 480px
              480: {
                slidesPerView: 1,
                spaceBetween: 20, // Adjust this value for medium screens if needed
              },
              // when window width is >= 768px
              768: {
                slidesPerView: 1,
                spaceBetween: 30, // Adjust this value for larger screens if needed
              },
              // when window width is >= 1024px
              1024: {
                slidesPerView: 2,
                spaceBetween: 30, // Adjust this value for extra-large screens if needed
              },
              1440: {
                slidesPerView: 3,
                spaceBetween: 30, // Adjust this value for extra-large screens if needed
              },
            }}
          >
            {loading ? (
              <>
                <SwiperSlide>
                  <ScoreHeaderSkeleton />
                </SwiperSlide>
                <SwiperSlide>
                  <ScoreHeaderSkeleton />
                </SwiperSlide>
                <SwiperSlide>
                  <ScoreHeaderSkeleton />
                </SwiperSlide>
              </>
            ) : (
              liveMatchData
                ?.filter((cardData) => {
                  if (getMatchType(cardData?.cst) === LIVE) {
                    return true; 
                  } else if (getMatchType(cardData?.cst) === UPCOMMING) {
                    const matchDate = cardData?.utc.split("T")[0];
                    return matchDate === today;
                  }
                  return false;
                })
                ?.map((cardData, i) => (
                  <SwiperSlide>
                    <ScoreCard
                      key={i}
                      cardData={cardData}
                      removeCard={removeCard}
                    />
                  </SwiperSlide>
                ))
            )}
          </Swiper>
          <div
            className="swiper-button-prev"
            style={{ position: "absolute", left: -20 }}
          ></div>
          <div
            className="swiper-button-next"
            style={{ position: "absolute", right: -20 }}
          ></div>
        </div>
      </div>
      {/* <div className="mr-10">
        <img src={"/assets/images/bowler.png"} alt="batter" style={{ height: "130px", borderRadius: "100%"}}/>
      </div> */}
    </section>
  );
};

export default ScoreHeader;
